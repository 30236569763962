.gradient {
  width: 100%;
  height: 100%;
  grid-row: 1/3;
  grid-column: 1/-1;
  background-image: linear-gradient(
    rgba(97, 97, 97, 0.534),
    rgba(97, 97, 97, 0.534)
  );
  // z-index: 1;
}
