.popup {
  background-color: rgba(#000, 0.9);
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  padding-bottom: 5rem;
  // only works on safari right now

  @supports (-webkit-backdrop-filter: blur(10px)) or
    (backdrop-filter: blur(10px)) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(#000, 0.3);
  }

  &:target {
    opacity: 1;
    visibility: visible;
  }
  &:target &__content {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  &__close {
    &:link,
    &:visited {
      top: 2.5rem;
      right: 4.5rem;
      position: absolute;
      font-size: 3rem;
      text-decoration: none;
      display: inline;
      color: #000;
    }
    &:hover {
      color: $color-primary;
    }
  }

  &__content {
    background-color: #fff;
    box-shadow: 0 2rem 4rem rgba(#000, 0.2);
    border-radius: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.25);
    width: 75%;
    z-index: 11000;
    display: table;
    @include respond(tab-land) {
      display: inline-block;
    }

    overflow: hidden;
    opacity: 0;
    transition: all 0.4s 0.5s;
  }
}

.application {
  &__close-popup {
    border: 1px solid #c23e3ee7;
    background-color: #c23e3ee7;
    border-radius: 50%;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    grid-column: 4/5;
    grid-row: 1/2;
    justify-self: end;
    align-self: start;
    margin: 3rem;
  }
  &-upload {
    display: grid;
    align-items: center;
    grid-column: 2/4;
    grid-row: 9/10;
    grid-template-columns: max-content 3% 5rem 1fr;

    @include respond(tab-port) {
      grid-row: 11/12;
    }
    &__text {
      font-size: 1.4rem;
      margin-left: 0.5rem;
    }

    &__section {
      border: 1px solid #c9c9c9e7;
      border-radius: 0;
      grid-column: 1/2;
      height: 3rem;
      grid-row: 2/3;
      display: grid;
      align-items: center;
      cursor: pointer;
      &--disabled {
        grid-column: 1/2;
        height: 3rem;
        grid-row: 2/3;
        border: none;
      }
    }

    &__button {
      width: 5rem;
      background-color: $color-primary;
      display: grid;
      height: 2rem;
      margin-left: 2rem;
      align-items: center;
      justify-items: center;
      justify-self: start;
      border-radius: 3px;
      grid-row: 1/2;
      grid-column: 3/4;
      cursor: pointer;
      &--text {
        font-size: 1.2rem;
      }
    }
    &__label {
      font-size: 2rem;
      margin-bottom: 1rem;
      grid-row: 1/2;
      grid-column: 1/2;
      color: #777;
    }
  }
  &__form-error {
    font-size: 1.4rem;
    color: rgb(161, 27, 27);
    border-top: 3px solid rgb(161, 27, 27);
    grid-row: 11/12;
    grid-column: 1/2;
    &--files {
      grid-row: 3/4;
      margin-top: 1rem;
    }
  }
  &__form-no-error {
  }
  &__form {
    display: grid;
    grid-template-columns: minmax(5%, 10%) repeat(2, 1fr) minmax(5%, 10%);
    padding: 0 2rem;
    align-items: center;

    @include respond(tab-port) {
      padding: 0;
    }
    &-header {
      font-size: 3.5rem;
      color: #000;
      grid-row: 1/2;
      grid-column: 2/4;
      margin: 2rem 0;
    }
    &--description {
      grid-row: 2/3;
      grid-column: 2/4;
      margin-bottom: 2rem;
    }
  }

  &__label {
    font-size: 2rem;
    margin-bottom: 0.2rem;
    color: #777;
    &--name {
      grid-column: 2/3;
      grid-row: 3/4;
      @include respond(tab-port) {
        grid-column: 2/4;
        grid-row: 3/4;
      }
    }
    &--email {
      grid-column: 3/4;
      grid-row: 3/4;
      margin-left: 5%;
      @include respond(tab-port) {
        grid-column: 2/4;
        grid-row: 5/6;
        margin-left: 0%;
      }
    }
    &--position {
      grid-row: 5/6;
      grid-column: 2/3;
      @include respond(tab-port) {
        grid-row: 7/8;
      }
    }
    &--coverLetter {
      grid-row: 7/8;
      grid-column: 2/3;
      margin-bottom: 2rem;
      @include respond(tab-port) {
        grid-row: 9/10;
      }
    }
  }

  &__button {
    grid-row: 10/11;
    align-self: center;
    color: #fff;
    font-size: 1.4rem;
    &:active {
      outline: none;
    }
    @include respond(tab-port) {
      align-self: center;
      grid-row: 12/13;
    }

    border: none;
    grid-column: 3/4;
    justify-self: end;
    color: #fff;
    padding: 1rem 2rem;
    font-size: 1.8rem;
    background-color: $color-secondary;
    transition: all 0.5s ease;
    margin: 2rem 0;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &:hover {
      transform: scale(1.1);
      box-shadow: 0 1rem 2rem #777;
    }
  }
  &__input {
    height: 3.5rem;
    font-size: 2rem;
    align-self: end;
    color: rgb(75, 75, 75);
    font-weight: 300;
    text-indent: 0.3rem;
    font-family: Titillium Web, sans-serif;
    font-weight: 100;
    border: 1px solid #c9c9c9e7;
    border-radius: 0;
    width: 100%;
    margin: 0rem 0rem 0rem 0rem;
    &:focus {
      outline: 1px solid #c9c9c9e7;
    }
    &--textarea {
      height: 15rem;
    }
    &--name {
      width: 95%;
      grid-column: 2/3;
      grid-row: 4/5;
      @include respond(tab-port) {
        width: 100%;
        grid-column: 2/4;
      }
    }
    &--email {
      width: 95%;
      grid-column: 3/4;
      justify-self: end;
      grid-row: 4/5;
      @include respond(tab-port) {
        width: 100%;
        grid-row: 6/7;
        justify-self: start;

        grid-column: 2/4;
      }
    }
    &--position {
      width: 100%;
      grid-column: 2/4;
      grid-row: 6/7;
      @include respond(tab-port) {
        width: 100%;
        grid-row: 8/9;
        grid-column: 2/4;
      }
    }
    &--coverLetter {
      width: 100%;
      grid-column: 2/4;
      grid-row: 8/9;
      height: 15rem;
      @include respond(tab-port) {
        width: 100%;
        grid-row: 10/11;
        grid-column: 2/4;
      }
    }
  }

  &__file-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row: 10/11;

    @include respond(tab-port) {
      grid-row: 12/13;
    }
    grid-column: 2/3;
    margin: 1.5rem 0;
    align-items: center;
    grid-template-rows: min-content;
  }
  &__file-row {
    display: grid;

    grid-template-columns: repeat(2, max-content);
    @include respond(tab-port) {
      grid-column: 1/2;
    }
    align-items: center;
    justify-items: start;
  }
  &__file-name {
    font-size: 1.9rem;
    list-style: none;
    grid-column: 1/2;
    margin-right: 1rem;
    justify-self: start;
    &--1 {
      grid-row: 1/2;
    }
    &--2 {
      grid-row: 2/3;
    }
    &--3 {
      grid-row: 3/4;
    }
    &--4 {
      grid-row: 4/5;
    }
    &--5 {
      grid-row: 5/6;
    }
  }
  &__delete-file {
    border: 1px solid #c23e3ee7;
    background-color: #c23e3ee7;
    border-radius: 50%;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    @include respond(tab-port) {
      font-size: 1.3rem;
    }
    justify-self: end;
    &--1 {
      grid-row: 1/2;
    }
    &--2 {
      grid-row: 2/3;
    }
    &--3 {
      grid-row: 3/4;
    }
    &--4 {
      grid-row: 4/5;
    }
    &--5 {
      grid-row: 5/6;
    }
  }
}
