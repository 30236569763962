.loader {
  &__container {
    display: grid;
    transform: translateY(10rem);
    height: 90vh;
    justify-content: center;
    align-content: center;
  }
  &__spinner {
    width: 100%;
    height: 8rem;
    transform: translateY(-10rem);
  }
}
