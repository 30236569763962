.banner {
  display: grid;
  grid-template-columns: minmax(8%, 1fr) minmax(45rem, 105rem) minmax(8%, 1fr);
  height: 50rem;
  justify-content: start;
  grid-template-rows: minmax(5%, 30%) minmax(20%, 30%) minmax(20%, 30%) minmax(
      5%,
      30%
    );
  @media only screen and (max-width: 50em) {
    height: 35rem;
  }

  &__img {
    object-fit: cover;
    height: 50rem;
    width: 100%;
    grid-column: 1/-1;
    grid-row: 1/-1;
    @media only screen and (max-width: 50em) {
      height: 35rem;
    }

    z-index: -2;
    backface-visibility: hidden;
  }
  z-index: 170;

  &__header {
    grid-row: 2/3;
    grid-column: 2/3;
    font-size: 6rem;
    margin-bottom: 0.5rem;
    align-self: end;
    @media only screen and (max-width: 52em) {
      font-size: 4rem;
    }
    font-weight: 100;
    color: #fff;
    animation: headerFade 0.5s ease-in 0.5s;
    animation-fill-mode: backwards;
  }
  &__secondary-header {
    grid-row: 3/4;
    grid-column: 2/3;
    margin-top: 0.5rem;
    align-self: start;
    font-size: 2.2rem;
    @media only screen and (max-width: 52em) {
      font-size: 1.7rem;
    }
    @media only screen and (max-width: 70.5em) {
      width: 70%;
    }
    @media only screen and (max-width: 38em) {
      width: 100%;
    }
    color: #fff;
    animation: secondaryFade 0.5s ease-in 1s;
    animation-fill-mode: backwards;
  }
}
.globe {
  width: 340rem;
  height: 338rem;
  fill: #1c355e;
}
