*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
  font-family: Titillium Web, sans-serif;
  font-weight: 300;
  background-color: #fff;
  scroll-behavior: smooth;
  @include respond(small-desktop) {
    font-size: 58%;
  }
  @include respond(tab-land) {
    font-size: 56.25%;
  }

  @include respond(tab-port) {
    font-size: 45%;
  }
  @include respond(phone) {
    font-size: 35%;
  }

  @include respond(big-desktop) {
    font-size: 75%;
  }
}

ul {
  list-style: none;
}
