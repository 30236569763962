.footer {
  display: grid;

  z-index: 200;
  grid-template-rows: repeat(2, min-content);

  &__main {
    grid-template-columns:
      minmax(11%, 1fr) repeat(3, minmax(15rem, 22rem)) minmax(25rem, 44rem)
      minmax(11%, 1fr);
    @include respond(tab-port) {
      grid-template-columns:
        minmax(11%, 1fr) repeat(3, minmax(15rem, 22rem))
        minmax(11%, 1fr);
    }
    grid-template-rows: 1fr minmax(min-content, max-content) 1fr;
    display: grid;
    background-color: rgb(39, 39, 39);
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  &__column {
    grid-row: 2/3;
    display: grid;
    grid-template-rows: min-content;
    font-weight: 200;
    & > * {
      padding: 1rem;
    }
    & > *:not(:first-child) {
      font-size: 1.4rem;
    }
    &--1 {
      grid-column: 2/3;
    }
    &--2 {
      grid-column: 3/4;
    }
    &--3 {
      grid-column: 4/5;
    }
  }
  &__logo {
    width: 100%;
    height: 6rem;
    align-self: start;
  }

  &__location {
    color: #fff;
    font-size: 2.5rem;
    font-weight: 400;
    &--info {
      font-weight: 200;
    }
  }
  &__info {
    color: #fff;
  }
  &__end {
    background-color: rgb(26, 26, 26);
    display: grid;
    grid-template-columns:
      minmax(11%, 1fr) minmax(25rem, 35rem) 10%
      minmax(35rem, 55rem) minmax(11%, 1fr);
    @include respond(tab-port) {
      grid-template-columns: minmax(11%, 1fr) minmax(45rem, 55rem) minmax(
          11%,
          1fr
        );
      grid-template-rows: 50% 50%;
    }
    align-items: center;
    justify-items: center;
  }
  &__copyright {
    grid-column: 2/3;
    font-size: 1.4rem;
    color: #888;
    justify-self: start;
    @include respond(tab-port) {
      justify-self: center;
    }
    grid-row: 1/2;
  }
  &__links {
    margin: 2rem 0;
    grid-row: 1/2;
    grid-column: 4/5;
    justify-self: end;
    @include respond(tab-port) {
      justify-self: center;
      grid-column: 2/3;
      grid-row: 2/3;
    }
  }
  &__link {
    width: 95%;
    font-size: 1.4rem;
    padding-right: 1rem;
    padding-left: 1rem;
    color: #fff;
    &:not(:first-child) {
      border-left: 1px solid #fff;
    }
  }
}
