.graphic {
  background-color: #fff;
  display: grid;
  grid-row: 4/5;
  grid-template-columns: 1fr 70% 1fr;
  justify-items: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  &__header {
    margin: 1rem 1rem;
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: start;
    @include respond(tab-port) {
      width: 120%;
      font-size: 2.6rem;
    }
    color: rgb(59, 59, 59);
  }
  &__text {
    align-self: end;
    grid-column: 2/3;
    grid-row: 2/3;
    width: 80%;
    @include respond(tab-port) {
      width: 120%;
    }

    color: rgb(78, 78, 78);
  }
  &__line {
    grid-column: 2/9;
    grid-row: 3/11;
    width: 80rem;
    align-self: center;
    @include respond(small-desktop) {
      width: 70rem;
      grid-column: 2/10;
    }
    @include respond(tab-land) {
      width: 60rem;
    }
    @media only screen and (max-width: 44em) {
      width: 50rem;
    }
    @media only screen and (max-width: 32em) {
      width: 40rem;
    }
    @media only screen and (max-width: 28em) {
      width: 30rem;
    }
  }
  &__section {
    grid-row: 3/4;
    grid-column: 2/3;
    display: grid;
    grid-template-rows: repeat(10, 6rem);
    grid-template-columns: repeat(10, 12.5rem);
    @include respond(tab-land) {
      grid-template-columns: repeat(10, 11.5rem);
    }
    @media only screen and (max-width: 44em) {
      grid-template-columns: repeat(10, 10.5rem);
      grid-template-rows: repeat(10, 5rem);
    }
    @media only screen and (max-width: 32em) {
      grid-template-columns: repeat(10, 9.5rem);
      margin-right: 5rem;
    }
    @media only screen and (max-width: 28em) {
      grid-template-columns: repeat(10, 8.5rem);
    }
    @media only screen and (max-width: 28em) {
      grid-template-columns: repeat(10, 7.5rem);
    }
    align-items: center;
    justify-items: center;
  }
  &-text {
    font-size: 1.7rem;
    color: rgb(59, 59, 59);
    font-weight: 400;
    width: 220%;
    text-align: center;
    transform: translateY(-8rem);
    @media only screen and (max-width: 35em) {
      transform: translateY(-6rem);
    }
    &--center {
      transform: translate(-8rem, -8rem);
      @include respond(small-desktop) {
        transform: translateY(-8rem);
      }
      @media only screen and (max-width: 35em) {
        transform: translateY(-6rem);
      }
    }
    &--right {
      @media only screen and (max-width: 35em) {
        transform: translateY(6rem);
      }
    }
  }
  &__circle-small {
    border-radius: 100%;
    border: dashed 5px #444;
    width: 12.4rem;
    height: 12.4rem;
    @include respond(tab-land) {
      border: dashed 2px #444;
      width: 10.4rem;
      height: 10.4rem;
    }
    @media only screen and (max-width: 32em) {
      width: 7.4rem;
      height: 7.4rem;
    }

    z-index: 100;
    background-color: #fff;
    display: grid;
    align-items: center;
    justify-items: center;
  }

  &--right {
    grid-row: 9/11;
    grid-column: 6/7;
    margin-left: 3rem;
    margin-bottom: 3rem;
    @include respond(small-desktop) {
      grid-column: 6/8;
      grid-row: 8/11;
      margin-left: 0rem;
    }
    @media only screen and (max-width: 32em) {
      margin-bottom: 4rem;
    }
    @media only screen and (max-width: 32em) {
      margin-bottom: 13rem;
      margin-right: 5rem;
    }
  }
  &--center {
    grid-row: 3/4;
    align-self: center;
    grid-column: 5/6;
    justify-self: start;
    margin-bottom: 3rem;
    @include respond(small-desktop) {
      grid-column: 5/7;
      grid-row: 3/5;
      justify-self: center;
    }
    @include respond(tab-land) {
      grid-row: 3/6;
    }
    @media only screen and (max-width: 32em) {
      margin-bottom: 4rem;
    }
    @media only screen and (max-width: 32em) {
      margin-bottom: -4rem;
    }
  }
  &--left {
    grid-row: 7/9;
    grid-column: 1/2;
    align-self: center;
    margin-left: 5rem;
    margin-top: 3rem;
    @include respond(small-desktop) {
      grid-column: 2/3;
      margin-left: 0rem;
      margin-top: 0rem;
    }
    @include respond(tab-land) {
      margin-left: 5rem;
    }
    @media only screen and (max-width: 44em) {
      margin-left: 10rem;
    }
    @media only screen and (max-width: 32em) {
      margin-left: 15rem;
      margin-bottom: 3rem;
    }
    @media only screen and (max-width: 28em) {
      margin-left: 20rem;
      margin-bottom: 3rem;
    }
  }
  &__circle-large {
    border-radius: 100%;
    border: dashed 5px #444;
    width: 30rem;
    height: 30rem;
    display: grid;
    align-content: center;
    justify-content: center;
    grid-column: 10/11;
    justify-self: end;
    margin-right: 4rem;

    @include respond(small-desktop) {
      border: dashed 2px #444;

      width: 23rem;
      height: 23rem;
      grid-column: 9/10;
      margin-right: 8rem;
      justify-self: center;
    }
    @include respond(tab-land) {
      width: 18rem;
      height: 18rem;

      margin-top: 5rem;
    }
    @media only screen and (max-width: 35em) {
      width: 14rem;
      height: 14rem;
      grid-column: 8/10;
      margin-top: 7rem;
      margin-right: 3rem;
    }

    z-index: 100;
    background-color: rgb(255, 255, 255);
    grid-row: 4/7;
  }
  &__icon {
    background-color: $color-secondary;
    border-radius: 100%;
    fill: #fff;
    width: 85%;
    padding: 1.5rem;
    height: 85%;
    z-index: 150;
    box-shadow: 0 0rem 3rem rgb(97, 97, 97);
  }
  &__sun {
    width: 8rem;
    grid-column: 2/3;
    grid-row: 2/3;
    fill: #555;
    @media only screen and (max-width: 30em) {
      grid-column: 3/5;
    }
  }
  &__cloud {
    width: 8rem;
    grid-column: 7/8;
    @include respond(small-desktop) {
      width: 5rem;
      height: 5rem;
      grid-column: 7/8;
    }
    @include respond(tab-port) {
      grid-column: 8/9;
    }

    margin-left: 2rem;
    grid-row: 3/4;
    animation: cloud 14s ease-in-out;
    animation-iteration-count: infinite;
    fill: #555;

    &--2 {
      width: 5rem;
      grid-column: 6/8;
      grid-row: 2/3;
      animation: cloud 14s ease-in-out;
      animation-iteration-count: infinite;
      margin-left: 2rem;
      fill: #555;
      @include respond(tab-port) {
        grid-column: 7/9;
      }
    }
  }
  &__image-carousel {
    z-index: 120;
    grid-column: 9/10;
    grid-row: 4/7;
    display: grid;
    align-items: center;
    justify-items: center;
    perspective: 140rem;
  }
  &__image {
    width: 28rem;
    height: 28rem;

    @include respond(small-desktop) {
      width: 20rem;
      height: 20rem;
    }
    @include respond(tab-land) {
      width: 16rem;
      height: 16rem;
    }
    @media only screen and (max-width: 35em) {
      width: 12rem;
      height: 12rem;
    }
    z-index: 120;
    grid-row: 1/2;
    grid-column: 1/2;
    border-radius: 100%;

    backface-visibility: hidden;
    &--1 {
      animation: rotateImage 30s;
      animation-iteration-count: infinite;
    }
    &--2 {
      animation: rotateImage2 30s;
      animation-iteration-count: infinite;
    }
    &--3 {
      animation: rotateImage3 30s;
      animation-iteration-count: infinite;
    }
    &--4 {
      animation: rotateImage4 30s;
      animation-iteration-count: infinite;
    }
  }
}
