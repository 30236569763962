.career {
  display: grid;
  &__section {
    grid-row: 3/4;
    display: grid;
    grid-template-columns: minmax(8%, 1fr) minmax(45rem, 105rem) minmax(8%, 1fr);
    align-items: end;
  }
  &__header {
    font-size: 3.5rem;
    color: #222;
    padding: 6rem 0;
    grid-column: 2/3;
  }
  &__button {
    grid-column: 2/3;
    margin: 6rem 0;
    justify-self: center;
    width: 30rem;
    height: 4rem;
    border-radius: 1rem;
    font-size: 1.9rem;
    background-image: radial-gradient($color-primary, $color-primary-one);
    text-decoration: none;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in;
    font-weight: 400;
    &:hover {
      transform: scale(1.1);
      box-shadow: 0 1rem 4rem #999;
    }
  }
  &__paragraph {
    color: #333;
    grid-column: 2/3;
  }
  &-table {
    display: grid;
    height: 55vh;
    grid-row: 2/3;
    grid-template-rows: 8rem repeat(6, min-content);
    grid-template-columns:
      minmax(15%, 1fr) repeat(3, minmax(10rem, max-content)) minmax(
        10rem,
        80rem
      )
      minmax(15%, 1fr);
    &__header {
      grid-row: 2/3;
      grid-column: 2/6;
      font-size: 3rem;
      color: #222;
    }

    &__column-header {
      grid-row: 4/5;
      grid-column: 2/6;
      // display: grid;
      justify-items: start;
      align-content: center;
      grid-template-columns: repeat(3, max-content) 1fr;
    }
    &__header-button {
      border: none;
      grid-row: 4/5;
      color: #555;
      background-color: inherit;
      font-size: 1.5rem;
      margin-right: 2rem;
      margin-top: 1rem;
      &--1 {
        grid-column: 2/3;
      }
      &--2 {
        grid-column: 3/4;
      }
      &--3 {
        grid-column: 4/5;
      }
      &--reset {
        justify-self: end;
        margin-right: 0;
        grid-column: 5/6;
      }
    }
    &__header-secondary {
      border-top: 2px solid rgb(201, 201, 201);
      border-bottom: 2px solid rgb(202, 202, 202);
      grid-row: 5/6;
      grid-column: 2/6;
      margin: 1rem 0;
      display: grid;
    }
    &__listings-shown {
      font-size: 2rem;
      grid-row: 1/2;
      margin: 0.5rem 0;
      color: #444;
      justify-self: start;
    }
    &__date-sort {
      justify-self: end;
      border: none;
      grid-row: 1/2;

      color: #555;
      background-color: inherit;
      font-size: 1.3rem;
    }
  }
}

.job-listing {
  &__border {
    grid-row: 6/7;
    border-bottom: 2px solid rgb(202, 202, 202);
    grid-column: 2/6;
  }
  &__column {
    grid-row: 6/7;
    justify-self: center;
    font-size: 1.5rem;
    font-weight: 400;
    color: #666;

    &--1 {
      grid-column: 2/3;
    }
    &--2 {
      grid-column: 3/4;
    }
    &--3 {
      grid-column: 4/5;
    }
  }
  &__apply {
    grid-row: 6/7;
    grid-column: 5/6;
    justify-self: end;
    width: 8rem;
    height: 2rem;
    border-radius: 1rem;
    font-size: 1.4rem;
    background-image: radial-gradient($color-primary, $color-primary-one);
    text-decoration: none;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in;
    z-index: 100;
    &:hover {
      transform: scale(1.1);
    }
  }
}
