.attributes {
  display: grid;
  grid-template-rows: repeat(4, min-content);
  background-color: #fff;

  &__title {
    grid-column: 1/-1;
    grid-row: 1/2;
    margin-top: 5rem;
    margin-bottom: 1rem;
    justify-self: center;
    text-align: center;

    font-size: 3rem;
  }
  &__title-text {
    grid-column: 1/-1;
    grid-row: 2/3;
    margin-bottom: 1rem;
    justify-self: center;
    text-align: center;
    margin-bottom: 5rem;

    font-size: 1.7rem;
    color: #444;
  }
  &__section {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns:
      minmax(5%, 1fr) minmax(5rem, 25rem) minmax(15rem, 60rem)
      minmax(5%, 1fr);
    justify-items: start;
    align-items: center;
    grid-template-rows: repeat(3, minmax(7rem, min-content));
    background-color: #fff;
    &--odd {
      background-color: #f4f4f4;
      border-top: 5px solid #f1f1f2;
    }
  }
  &__header {
    opacity: 0;
    &--animation {
      grid-column: 3/4;
      grid-row: 1 / 2;
      font-size: 3.5rem;
      animation: inLeft 0.9s ease-in-out;
      margin-left: 2rem;
    }
  }
  &__text {
    opacity: 0;

    &--animation {
      grid-column: 3/4;
      grid-row: 2 / 3;
      color: #444;
      font-size: 1.5rem;
      animation: inLeft 0.9s ease-in-out;
      margin-left: 2rem;
    }
  }
  &__link {
    opacity: 0;
    &--animation {
      color: #444;
      text-decoration: none;
      grid-column: 3/4;
      grid-row: 3 / 4;
      font-size: 1.4rem;
      animation: inLeft 0.9s ease-in-out;
      margin-left: 2rem;
    }
  }

  &__icon {
    opacity: 0;
    &--animation {
      grid-column: 2/3;
      grid-row: 1 / -1;
      height: 80%;
      width: 80%;
      margin-right: 2rem;
      justify-self: center;
      fill: #264977;
      animation: inRight 0.9s ease-in-out;
      // @include respond(phone) {
      //   height: 60%;
      //   width: 60%;
      // }
    }
  }
}
